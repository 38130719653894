import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

const TeamPage = () => (
  <Layout>
    <SEO
      title="Unser Team - Leidenschaftliche Experten für japanische Küche"
      description="Erfahren Sie mehr über unser engagiertes Team von talentierten Köchen und freundlichem Servicepersonal bei Kyoto Sushi Bar in Paderborn. Unsere Leidenschaft für japanische Küche spiegelt sich in jedem Gericht wider, das wir zubereiten. Wir freuen uns darauf, Ihnen ein unvergessliches kulinarisches Erlebnis zu bieten, das von unserem Team mit Hingabe und Fachwissen geleitet wird. Besuchen Sie uns und lassen Sie sich von unserem professionellen Team verwöhnen"
      keywords="Team, Kyoto, Sushi, japanisches Restaurant, Paderborn, Mitarbeiter, Leidenschaft, Expertise, Teamarbeit, kulinarisches Know-how, Service, Gastfreundschaft, Qualität, Erfahrung, Professionalität, Engagement, Kundenorientierung, Restaurantbranche"
    />
    <section className="page">
        <div className="image md:mx-0">
            <StaticImage
            src="../images/kyoto-team.jpg"
            layout={'fullWidth'}
            quality={80}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Kyoto Koch"
            className="block"
            />
        </div>
        <div class="content">
            <div className="py-5 px-10">
                <h1 className="text-center ">
                  <div className="text-2xl font-medium uppercase">
                    Willkommen bei Kyoto,
                  </div>
                  <div>dem japanischen Restaurant in Paderborn!</div>
                </h1>
                <div className="my-4">
                  <p className="mb-2">Inspiriert von der reichen kulinarischen Tradition Japans haben wir es uns zur Aufgabe gemacht, authentische japanische Gerichte mit einer modernen Note zu servieren. Unser Restaurant bietet Ihnen eine einzigartige kulinarische Erfahrung, bei der traditionelle Zubereitungstechniken und frische Zutaten im Mittelpunkt stehen.</p>
                  <p className="mb-2">Unser Name "Kyoto" ist eine Hommage an die alte Hauptstadt Japans, die für ihre raffinierte Küche und ihre kulturelle Bedeutung bekannt ist. Ähnlich wie Kyoto, möchten wir Ihnen eine vielfältige Auswahl an Gerichten präsentieren, die sowohl ansprechend für das Auge als auch für den Gaumen sind.</p>
                  <p className="mb-2">Unsere talentierten Köche haben eine Leidenschaft für die Kunst des Sushi, und wir sind stolz darauf, einige der besten Sushi-Rollen der Stadt anzubieten. Jede Rolle wird sorgfältig von Hand zubereitet und mit frischen Meeresfrüchten, Gemüse und Reis von höchster Qualität gefüllt. Darüber hinaus bieten wir eine Vielzahl anderer japanischer Spezialitäten an, darunter Tempura, Ramen, Teriyaki und vieles mehr. Unsere Speisekarte wird regelmäßig aktualisiert, um Ihnen immer neue und aufregende Geschmackserlebnisse zu bieten.</p>
                  <p className="mb-2">Bei Kyoto legen wir großen Wert auf Qualität und Service. Unser Team steht Ihnen jederzeit zur Verfügung, um Ihre Fragen zu beantworten und Ihnen bei der Auswahl der perfekten Gerichte zu helfen. Unser gemütliches und modernes Ambiente lädt zum Verweilen ein, sei es für ein romantisches Abendessen zu zweit, ein Treffen mit Freunden oder eine Geschäftsveranstaltung.</p>
                  <p className="mb-2">Wir freuen uns darauf, Ihnen die faszinierende Welt der japanischen Küche näherzubringen und Ihnen eine unvergessliche gastronomische Erfahrung zu bieten. Besuchen Sie uns im Herzen von Paderborn und entdecken Sie die Aromen Japans bei Kyoto.</p>
              </div>
            </div>
        </div>
    </section>
  </Layout>
)

export default TeamPage